<template>
    <van-popup v-model="showSku" position="bottom" closeable close-on-popstate class="m-num m-padding" @close="handleClose">
        <div class="num">
            <div>数量：</div>
            <van-stepper v-model="number" integer :min="1" :max="maxNum"></van-stepper>
        </div>
        <div class="confirm" @click="confirm">
            <span>确定</span>
        </div>
    </van-popup>
</template>
<script>
export default {
    props:['show','maxNum'],
    data() {
        return {
            number: 1,
            showSku:false
        }
    },
    watch: {
        show(value){
            this.showSku = value
            this.number = 1
        },
    },
    methods: {
        // 关闭弹窗触发
        handleClose(){
            this.$emit('close', false)
        },
        confirm() {
            this.$emit('confirm', this.number)
        }
    }
};
</script>
<style scoped lang="less">
    .m-num {
        min-height: 100px;
        border-radius:5px 5px 0px 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        .num {
            display: flex;
            margin-bottom: 20px;
        }
        .confirm {
            display: flex;
            justify-content: flex-end;
            span {
                display: inline-block;
                width: 100px;
                background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 10px;
                color: #fff;
            }
        }
    }
</style>
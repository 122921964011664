<template>
  <section class="m-goods m-scroll"  @scroll="handleScroll($event)"  ref="wrapper">

    <!-- 视频 & 轮播图 -->
    <goods-swipe ref="banner" :images="goodsInfo.round_pic_url" :videoData="goodsInfo.video_url == '' ? {} : goodsInfo.video_url"
    :hasVideo="goodsInfo.video_url != null && goodsInfo.video_url != ''" :goodsInfo="goodsInfo"></goods-swipe>

    <!-- 头部导航 -->
    <div class="head-bar m-padding" ref="bar">
      <img @click="goodsBack" src="@/assets/goods/icon_back_2@2x.png" alt="img">
      <div>
        <router-link to="/zh/cart" >
          <img src="@/assets/goods/cart.png" alt="img" :class="{'cart':cartAnimate}">
          <m-dot :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
        <router-link to="/">
          <img src="@/assets/goods/icon_home_3@2x.png" alt="">
        </router-link>
      </div>
    </div>
    <div class="goods">
      <!-- 商品价格 -->
      <div class="goods-price m-padding">
        <div class="price-left">
          <div class="title">福利砍价</div>
          <div class="price">
            当前价格
            <span v-if="goodsSku.goods_sku.price - user.bargain_user_bargain_log_sum != goodsSku.goods_sku.price">${{(goodsSku.goods_sku.price - user.bargain_user_bargain_log_sum).toFixed(2)}}</span>
            <span>${{goodsSku.goods_sku.price}}</span>
          </div>
        </div>
        <div class="price-right">
          <div class="over">距砍价结束</div>
          <div class="count">
            <van-count-down :time="goodsSku.remaining_time">
              <template #default="timeData">
                <span class="block">{{ timeData.days > 9 ? timeData.days : '0' + timeData.days }}天</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.hours  > 9 ? timeData.hours : '0' + timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
      <!-- 商品名称 -->
      <div class="goods-title is-margin">
        <div class="title">
          <img v-if="goodsSku.goods_sku.image" :src="goodsSku.goods_sku.image.remote_url" alt="">
          <div>
            <p class="van-multi-ellipsis--l2">{{goodsInfo.goods_info.name}}</p>
            <p class="sku">{{goodsSku.goods_sku.attr_symbol_string}}</p>
          </div>
          <!-- <div class="share">
            <p>活动</p>
            <p>规则</p>
          </div> -->
        </div>
        <div class="haggle-content">
          <div class="haggle-price" v-if="user.bargain_user_bargain_log_sum != (goodsSku.goods_sku.price - goodsSku.sales_price).toFixed(2)">
              已获取优惠<span> ${{user.bargain_user_bargain_log_sum ? user.bargain_user_bargain_log_sum: 0}}</span>, 最高可优惠${{(goodsSku.goods_sku.price - goodsSku.sales_price).toFixed(2)}}
          </div>
          <div class="haggle-price" v-else>
              已砍成功，获取优惠 <span>${{user.bargain_user_bargain_log_sum}}</span>
          </div>
          <van-progress :percentage="user.get_off" stroke-width="10" :show-pivot="false" />
          <div v-if="user.get_off != 100">
            <div class="share-btn" @click="handleShare">
                {{user.id == '' ? '立即砍价' : '分享好友帮忙获取优惠'}}
            </div>
            <div class="btn-tip" v-if="user.id == ''">邀请好友，帮忙砍价获取优惠</div>
            
            <div class="buy" v-else-if="goodsSku.cut_type == 0" @click="handleBuy(user)">
                {{user.order ? user.order.order_status == 0?'继续支付':'立即购买' : '立即购买'}}
            </div>
          </div>
          <div v-else>
              <div class="share-btn" @click="handleBuy(user)">
                  {{user.order ? user.order.order_status == 0?'继续支付':'立即购买' : '立即购买'}}
              </div>
              <div class="addCart" @click="addCart">加入购物车</div>
          </div>
          <div class="hanggle">
            <div>
              <p>限购数量</p>
              <p>{{goodsSku.buy_limit}}</p>
            </div>
            <div>
              <p>砍价库存</p>
              <p>{{goodsSku.sales_stock}}</p>
            </div>
            <div>
              <p>需砍次数</p>
              <p>{{goodsSku.count}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="haggle-detail is-margin">
        <div class="detail-top">
          <div class="help-title">
            <div>
              <img src="@/assets/bargain/Vector-min.png" alt="">
              <span>好友助力详情</span>
            </div>
          </div>
        </div>
        <div class="detail-content">
          <div class="detail-r">
            <div class="r-item" v-for="item in helpList" :key="item.id">
              <div class="item-time">
                <p>好友已帮你获得优惠</p>
                <p>{{item.created_at}}</p>
              </div>
              <div class="item-price">
                <span>- ${{item.cut_price}}</span>
              </div>
            </div>
            <div class="no-help" v-if="helpList.length == 0">—— &nbsp;暂无好友助力&nbsp; ——</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品详情图 -->
    <m-divider title="商品详情" class="detail-title"></m-divider>
    <div class="detail-img">
      <img :src="item.url" v-for="(item, index) in goodsInfoImg" :key="index" alt="">
    </div>
    <!-- 回到顶部 -->
    <m-scroll-top :show="scrollTop" :dom="$refs.wrapper"></m-scroll-top>
    <!-- 数量弹窗 -->
    <m-num :maxNum="maxNum" :show="showNum" @close="val=>showNum=val" @confirm="handleConfirm"></m-num>

    <!-- 邮箱绑定 -->
    <van-dialog v-model="emailShow" :showConfirmButton="false" class="bind-box">
      <div class="bind-input">
        <div>邮箱：</div>
        <input type="text" v-model="email" placeholder="请输入您的邮箱">
      </div>
      <p style=" font-size: 14px;margin: 0 0 10px;">该邮箱用于提醒您砍价商品的进度</p>
      <div class="bind-btn">
        <span @click="emailShow = false">关闭</span><span  @click="handleBind">确定</span>
      </div>
    </van-dialog>
  </section>
</template>

<script>
import GoodsSwipe   from '@/components/zh/goods-swipe.vue'
import MDot         from '@/components/zh/m-dot.vue'
import MScrollTop   from '@/components/zh/m-scroll-top.vue'
import MNum         from '@/components/zh/m-num.vue'
import MDivider     from '@/components/zh/m-divider.vue'

import { getGoodsInfo, } from '@/api/zh/detail.js'
import { goodsSku_detail,join_haggle,haggle_log, live_email, add_email } from '@/api/zh/haggle.js'
import { addCart } from '@/api/zh/cart.js'
import { getAccountInfo } from "@/api/zh/mine.js";
export default {
  name:'Index',
  components:{ GoodsSwipe, MDot, MScrollTop, MNum, MDivider },
  data(){
    return {
      goodsId: '',
      cartAnimate:false,
      dotAnimate:false,
      scrollTop:false,
      goodsInfo: {
        min_price: 0,
        max_price: 0,
        off: 0,
        pre_info: '',
        is_pre: '',
        goods_info: ''
      },
      goodsInfoImg: {},
      skuData: [],
      isNew: false,
      selectSkuList: [],
      timer: null,
      isPre: false,
      goods: {}, // 活动信息
      goodsSku: {goods_sku:{image:''}}, // 商品sku信息
      user: {get_off: 0,id:'',ip:'',bargain_user_bargain_log_sum:'0',phone:'',email:''}, // 砍价信息
      helpList: [],
      haggle_sku_id:'', 
      flag: true, //  假设未参加砍价  false  
      showNum: false,
      maxNum: '',
      buyObj: {},
      emailShow: false, // 绑定邮箱显示  
      email: '', // 邮箱
    }
  },
  created() {
    this.goodsId = this.$route.params.id
    this.$store.dispatch('getCart')
    this.getEmail()
  },
  mounted(){
    this.getGoodsInfoHandle();
    if(this.$route.query.haggle_id) {
      this.haggle_sku_id = this.$route.query.haggle_id
      this.getGoodsSkuHaggle()
      this.flag = false
    } else {
      let haggleGoods = JSON.parse(localStorage.getItem('haggleGoods'))
      this.goodsSku = JSON.parse(localStorage.getItem('goodsSKU'))
      this.goodsSku.join_user_count_total = haggleGoods.join_user_count_total
      this.goodsSku.remaining_time = haggleGoods.remaining_time*1000
      if(sessionStorage.getItem('haggle_flag') == 1) {
        this.flag = false
      } else {
        this.flag = true
      }
      
      if(sessionStorage.getItem('haggle_id')) {
        this.haggle_sku_id = sessionStorage.getItem('haggle_id')
        this.getGoodsSkuHaggle()
      }
    }
  },
  methods:{
    goodsBack() {
      // 返回
      if(this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.go(-1)
      }
    },
    // 参与活动
    handleShare() {
       let data = {
        sales_price: this.goodsSku.sales_price,
        price: this.goodsSku.goods_sku.price,
        barcode: this.goodsSku.goods_sku.barcode,
        id: this.goodsSku.goods_sku.goods_id,
        name: this.goodsInfo.goods_info.name,
      }
      // this.flag == true 参加砍价活动 走接口 反之直接跳转分享页
      if(this.flag) {
        join_haggle({bargain_goods_sku_id:this.$route.query.id}).then(res => {
          if(res) {
            sessionStorage.setItem('haggle_id',res.data.id)
            this.$router.push({path:'/zh/haggleShare', query: {id: res.data.id}})
            this.flag = false
            sessionStorage.setItem('haggle_flag', 1)
            localStorage.setItem('userId',res.data.user.id)
            if(res.data.user.phone != '') {
              data.phone = res.data.user.phone
            }
            if(res.data.user.email != '') {
              data.email = res.data.user.email
            }
            sessionStorage.setItem('haggleShare', JSON.stringify(data))
          }
        })
      } else {
        this.$router.push({path:'/zh/haggleShare', query: {id: this.user.id}})
      }
     
      if(this.user.phone != '') {
        data.phone = this.user.phone
      }
      if(this.user.email != '') {
        data.email = this.user.email
      }
      sessionStorage.setItem('haggleShare', JSON.stringify(data))
    },
    handleBuy(user) {
      if(user.order) {
        if(user.order.order_status != 0) {
          this.$router.push('/zh/order/1?back=account')
        }
      } else {
        this.maxNum = this.goodsSku.buy_limit
        this.showNum = true
        this.buyObj = {
            buyId: this.goodsSku.goods_sku_id,
            haggle_id: this.user.bargain_goods_sku_id
        }
      }
    },
    handleConfirm(num) {
      this.buyObj.buyNum = num
      this.showNum = false
      this.$router.push({ path: '/zh/buynow', query: this.buyObj  })
    },
    // 加入购物车
    addCart() {
      let params = {
        goods_id: this.goodsSku.goods_sku.goods_id,
        barcode: this.goodsSku.goods_sku.barcode,
        bargain_goods_sku_id: this.goodsSku.id,
        buy_number: 1
      }
      addCart(params).then(res => {
        if(res) {
          this.handleCartSuccess()
          let timer = setTimeout( () => {
              this.$store.commit('addCart', 1)
              clearTimeout(timer)
          }, 600)
        }
      })
    },
    handleCartSuccess(){
      this.cartAnimate = true

      let timer = setTimeout( () => {
          this.cartAnimate = false
          this.dotAnimate = true 
          let timer1 = setTimeout(()=> {
              this.dotAnimate = false
                  clearTimeout(timer1)
              },400)
          clearTimeout(timer)
      },660)
    },
    // 内容滑动事件
    handleScroll(e){
      let a =  (e.target.scrollTop / 375) > 0.45  ?  0.45 : (e.target.scrollTop / 375)
      this.$refs.bar.style.background = `rgba(0,0,0, ${a})`
      if(e.target.scrollTop >= 500){
        this.scrollTop = true
      }else{
        this.scrollTop = false
      }
      this.$refs.bar.style.opacity = 0
      // let timer = null
      clearTimeout(this.timer)
      this.timer =  setTimeout( () => { 
        this.$refs.bar.style.opacity = 1
      },300) 
    },
    // 获取商品SKU砍价信息
    getGoodsSkuHaggle () {
      goodsSku_detail(this.haggle_sku_id).then(res => {
        if(res) {
          this.getHelpList()
          this.goodsSku = res.data.bargain_goods_sku
          this.goodsSku.join_user_count_total = res.data.bargain_goods.join_user_count_total
          this.goodsSku.remaining_time = res.data.bargain_goods.remaining_time*1000
          this.user = res.data.bargain_user_goods_sku
          this.user.phone = res.data.bargain_user_goods_sku.user.phone
          this.user.email = res.data.bargain_user_goods_sku.user.email
          this.user.get_off = (this.user.bargain_user_bargain_log_sum / (this.goodsSku.goods_sku.price - this.goodsSku.sales_price)*100).toFixed(0)
          localStorage.setItem('userId',res.data.bargain_user_goods_sku.user.id)
        }
      })
    },
    getGoodsInfoHandle() {
      // 获取商品详细信息
      getGoodsInfo(this.$route.params.id).then(res => {
        if(res) {
          this.goodsInfo = res.data;
          this.goodsInfoImg = res.data.goods_info.goods_details_url
        } 
      })
    },
    // 获取好友助力列表
    getHelpList() {
      let params = {
        bargain_goods_sku_id: this.goodsSku.id,
        bargain_user_goods_sku_id: this.haggle_sku_id
      }
      haggle_log(params).then(res => {
        if(res.code == 20000) {
          this.helpList = res.data.data
        }
      })
    },
    getEmail() {
      getAccountInfo().then(res => {  
        if(res){
          this.email = res.data.email;
        }
      })
      live_email().then(res => {
        if(res) {
          if(Object.keys(res.data).length == 0) {
            this.emailShow = true
          }
        }
      })
    },
    // 绑定邮箱
    handleBind() {
      let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if(!reg.test(this.email) || this.email == '') {
        this.$notify({ type: 'warning', message: '邮箱格式错误' });
        return false
      }
      add_email({email: this.email}).then(res => {
        if(res) {
          this.emailShow = false
        }
      }) 
    },
  },
  watch: {
    '$route.params.id'(val) {
      if(val) {
        this.$refs.wrapper.scrollTop = 0
        this.selectSkuList = []
        this.getGoodsInfoHandle()
        // this.getGoodsSkuHaggle()
        this.isNew = false
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
.m-goods {
  padding: 0 !important;
}
.haggle-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  border-radius: 0 0 8px 8px;
  background: #fff;
  margin-bottom: 10px;
  margin-top: 15px;
  .haggle-price {
    font-size: 14px;
    color: #808080;
    span {
      color: #EF5D56;
      font-weight: bold;
    }
  }
  .van-progress {
    width: 300px;
    margin: 4px 0 16px;
    .van-progress__portion {
        background: linear-gradient(90.19deg, #6EFFF6 -6.08%, #35B6FF 105.58%);
    }
  }
  .share-btn {
    font-size: 18px;
    color: #fff;
    width: 311px;
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    margin-bottom: 5px;
    background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
    text-align: center;
  }
  .btn-tip {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  .buy {
    width: 66px;
    height: 21px;
    font-size: 16px;
    text-decoration: underline;
    margin:5px auto 10px;
    color: #004EBD;
  }
  .addCart {
    text-align: center;
    width: 85px;
    height: 21px;
    font-size: 16px;
    margin:0 auto 16px;
    color: #004EBD;
    text-decoration: underline;
  }
}
.haggle-detail {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(128, 128, 128, 0.1);
  background: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  color: #333;
  .detail-top {
    width: 100%;
    .help-title {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      &>div {
        flex: 1;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        img {
          width: 18px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
  .detail-content {
    background: #fff;
    overflow: hidden;
    .detail-r {
      width: 100%;
      padding: 8px;
      .r-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        border-bottom: 1px solid #EFEFEF;
        padding: 5px 0 15px;
        .item-time{
          p:nth-child(1) {
            font-size: 14px;
            margin-bottom: 5px;
          }
          p:nth-child(2) {
            color: #999999;
          }
        }
        .item-price {
          span {
            color: #ED2A24;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .no-help {
        text-align: center;
        color: #cecece;
      }
    }
  }
}
.goods-title {
  .title {
    display: flex;
    img {
      width: 80px;
      height: 80px;
      margin-right: 8px;
    }
    >div {
      P:nth-child(2) {
        margin-top: 10px;
        color: #666;
        font-size: 14px;
      }
    }
  }
}
/deep/.van-progress__portion {
  background: linear-gradient(90.19deg, #6EFFF6 -6.08%, #35B6FF 105.58%);
}
.bind-box {
  padding: 20px 12px;
  .bind-input {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    input {
      flex: 1;
      height: 100%;
      border: 1px solid #666;
      border-radius: 12px;
      outline: none;
      padding-left: 10px;
      font-size: 14px;
    }
  }
  .bind-btn {
    display: flex;
    justify-content: center;
    span {
      &:nth-child(1) {
          display: inline-block;
          width: 100px;
          border: solid 1px rgb(161, 161, 161);
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 10px;
          color: rgb(153, 153, 153);
      }
      &:nth-child(2) {
          display: inline-block;
          width: 100px;
          margin-left: 10px;
          background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 10px;
          color: #fff;
      }
    }
  }
}
</style>